<template>
  <v-card color="white">
    <v-toolbar color="light-blue" class="primary">
      <v-toolbar-title class="white--text">
        Create
      </v-toolbar-title>
    </v-toolbar>
    <template>
      <v-progress-linear color="accent" style="margin-top: 0px" />
    </template>
    <v-container>
      <v-flex class="text-xs-center">
        <v-subheader>What do you want to do?</v-subheader>
      </v-flex>
      <v-flex class="text-xs-center">
        <v-btn-toggle v-model="toggle_exclusive_action_type" depressed>
          <v-btn
            outline
            depressed
            color="primary"
            @click="setActionTypeToCreate"
          >
            Create
          </v-btn>
          <v-btn
            outline
            depressed
            color="primary"
            @click="setActionTypeToUpdate"
          >
            Update
          </v-btn>
          <v-btn
            outline
            depressed
            color="primary"
            @click="setActionTypeToKeywords"
          >
            Add Keywords
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-container>
    <content-plan-create v-if="actionType == 'Create'" />
    <content-plan-update v-if="actionType == 'Update'" />
    <content-plan-add-keywords v-if="actionType == 'Keywords'" />
  </v-card>
</template>
<script type="text/babel">
import ContentPlanCreate from "./ContentPlanCreate";
import ContentPlanUpdate from "./ContentPlanUpdate";
import ContentPlanAddKeywords from "./ContentPlanAddKeywords";

export default {
  name: "content-plan-actions",
  components: {
    ContentPlanCreate,
    ContentPlanUpdate,
    ContentPlanAddKeywords
  },
  data: () => {
    return {
      entityName: "content-plan",
      actionType: null,
      toggle_exclusive_action_type: null
    };
  },
  computed: {},
  methods: {
    setActionTypeToCreate() {
      this.actionType = "Create";
    },
    setActionTypeToUpdate() {
      this.actionType = "Update";
    },
    setActionTypeToKeywords() {
      this.actionType = "Keywords";
    }
  }
};
</script>
