<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12>
            <v-upload
              v-model="file"
              :progress="uploadProgress"
              :error-messages="errors.collect('upload')"
              data-vv-name="upload"
            />
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-btn flat color="orange" :disabled="loading" @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          flat
          color="accent"
          :disabled="loading"
          :loading="loading"
          @click="validate(upload)"
        >
          <v-icon>far fa-fw fa-file-upload</v-icon>
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import api from "@/api";
import VUpload from "../generic/input/VUpload";
import CanValidate from "../mixins/validation/CanValidate";

export default {
  name: "upload-content-plan-dialog",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: { VUpload },
  mixins: [CanValidate],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    file: undefined,
    loading: false,
    uploadProgress: undefined
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", false);
      }
    }
  },
  methods: {
    async upload() {
      this.loading = true;
      await api.contentPlan.uploadImport({
        file: this.file,
        progressCb: this.onUploadProgress
      });
      this.loading = false;
      this.$store.dispatch("snackbar/addItem", {
        text: `Your content plan import file was successfully uploaded. You will be notified once it's processed.`,
        color: "success"
      });
      this.dialog = false;
    },
    onUploadProgress: function(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.debug(
        `setting progress: ${progressEvent.loaded} / ${progressEvent.total}`
      );
    }
  }
};
</script>
