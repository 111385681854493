<template>
  <v-container>
    <v-subheader>What do you want to update?</v-subheader>
    <v-flex class="text-xs-center" style="min-height: 60px !important;">
      <v-btn-toggle v-model="toggle_exclusive_update_type" depressed>
        <v-btn outline depressed color="primary" @click="setUpdateTypeContent">
          Content Plan
        </v-btn>
        <v-btn outline depressed color="primary" @click="setUpdateTypeSubject">
          Subject Topic
        </v-btn>
      </v-btn-toggle>
    </v-flex>
    <!-- ContentPlan -->
    <v-container v-if="updateType == 'ContentPlan'" fluid>
      <v-flex xs4>
        <entity-data-provider
          entity-name="contentPlan"
          :includes="[
            'subjectTopics.keywordClusters.keywords',
            'localization',
            'subjectTopics.localization'
          ]"
        >
          <template slot-scope="props">
            <v-autocomplete
              v-model="contentPlanToUpdate"
              :loading="props.isLoading"
              :disabled="props.isLoading"
              :items="props.data"
              clearable
              item-text="title"
              item-value="id"
              label="Content Plan"
            />
          </template>
        </entity-data-provider>
      </v-flex>
      <template v-if="contentPlanToUpdate !== null">
        <v-subheader style="padding: 0px;">
          Content Plan Title:
        </v-subheader>
        <locale-textbox
          :specific-locales="specificLocales"
          :label-locales.sync="contentPlanTitle"
          :locales-text="contentPlanTitle"
        />
      </template>
    </v-container>
    <!-- SubjectTopic -->
    <v-container v-if="updateType == 'SubjectTopic'" fluid>
      <v-flex xs4>
        <entity-data-provider
          entity-name="contentPlan"
          :includes="[
            'subjectTopics.keywordClusters.keywords',
            'localization',
            'subjectTopics.localization'
          ]"
        >
          <template slot-scope="props">
            <v-autocomplete
              v-model="selectedContentPlan"
              :loading="props.isLoading"
              :disabled="props.isLoading"
              :items="props.data"
              clearable
              item-text="title"
              item-value="subjectTopics"
              label="Content Plan"
            />
          </template>
        </entity-data-provider>
        <v-autocomplete
          v-if="selectedContentPlan !== null"
          v-model="subjectTopicToUpdate"
          :items="subjectTopicsToUpdate"
          clearable
          item-text="title"
          item-value="id"
          label="Subject Topic"
        />
      </v-flex>
      <v-subheader v-if="subjectTopicToUpdate !== null" style="padding: 0px;">
        Subject Topic Title:
      </v-subheader>
      <locale-textbox
        v-if="subjectTopicToUpdate !== null"
        :specific-locales="specificLocales"
        :label-locales.sync="subjectTopicTitle"
        :locales-text="subjectTopicTitle"
      />
    </v-container>
    <v-flex v-if="titleSet" class="text-xs-center">
      <v-btn
        v-if="titleSet"
        outline
        color="primary"
        @click="update(updateType)"
      >
        update {{ updateType }}
      </v-btn>
    </v-flex>
  </v-container>
</template>
<script type="text/babel">
import { forOwn, cloneDeep } from "lodash";
import EntityDataProvider from "@/components/legacy/generic/data/EntityDataProvider";
import LocaleTextbox from "../generic/LocaleTextbox";

export default {
  name: "content-plan-update",
  components: {
    LocaleTextbox,
    EntityDataProvider
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      toggle_exclusive_update_type: null,
      updateType: null,
      contentPlanToUpdate: null,
      subjectTopicToUpdate: null,
      selectedContentPlan: null,
      selectedSubjectTopic: null,
      contentPlanSubjectTopics: null,
      selectedSubjectTopicKeywordClusters: null,
      selectedKeywordCluster: null,
      selectedSubjectTopicId: null,
      contentPlanTitle: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      },
      subjectTopicTitle: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      },
      keywordClusterTitle: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      },
      titleSet: false,
      specificLocales: ["nl_be", "fr_be"]
    };
  },
  computed: {
    contentPlan() {
      return this.$store.getters["entities/getEntity"]({
        name: "contentPlan",
        id: this.contentPlanToUpdate
      });
    },
    subjectTopic() {
      return this.$store.getters["entities/getEntity"]({
        name: "subjectTopic",
        id: this.subjectTopicToUpdate
      });
    },
    subjectTopics() {
      return this.$store.getters["entities/getEntities"]({
        name: "subjectTopic",
        ids: this.contentPlanSubjectTopics
      });
    },
    subjectTopicsToUpdate() {
      return this.$store.getters["entities/getEntities"]({
        name: "subjectTopic",
        ids: this.selectedContentPlan
      });
    },
    keywordClusters() {
      return this.$store.getters["entities/getEntities"]({
        name: "keywordCluster",
        ids: this.subjectTopic.keywordClusters
      });
    }
  },
  watch: {
    contentPlanTitle: {
      deep: true,
      handler() {
        this.titleIsSet(this.contentPlanTitle);
      }
    },
    subjectTopicTitle: {
      deep: true,
      handler() {
        this.titleIsSet(this.subjectTopicTitle);
      }
    },
    keywordClusterTitle: {
      deep: true,
      handler() {
        this.titleIsSet(this.keywordClusterTitle);
      }
    },
    contentPlanToUpdate: {
      deep: true,
      handler() {
        this.contentPlanTitle = cloneDeep(this.contentPlan.localization);
      }
    },
    subjectTopicToUpdate: {
      deep: true,
      handler() {
        this.subjectTopicTitle = cloneDeep(this.subjectTopic.localization);
      }
    }
  },
  methods: {
    setUpdateTypeContent() {
      this.updateType = "ContentPlan";
    },
    setUpdateTypeSubject() {
      this.updateType = "SubjectTopic";
    },
    setUpdateTypeKeyword() {
      this.updateType = "KeywordCluster";
    },
    async update(type) {
      switch (type) {
        case "ContentPlan":
          await this.$store.dispatch("entities/updateContentPlan", {
            contentPlanId: this.contentPlanToUpdate,
            entity: this.contentPlanTitle
          });
          break;
        case "SubjectTopic":
          await this.$store.dispatch("entities/updateSubjectTopic", {
            subjectTopicId: this.subjectTopicToUpdate,
            entity: this.subjectTopicTitle
          });
          break;
      }
    },
    titleIsSet(object) {
      forOwn(object, value => {
        if (value === "") {
          this.titleSet = false;
        }
      });
      forOwn(object, value => {
        if (value !== null && value !== "") {
          this.titleSet = true;
        }
      });
    }
  }
};
</script>
