<template>
  <v-card color="white">
    <v-dialog
      v-if="addRemark"
      v-model="internalDialog"
      max-width="400"
      color="white"
    >
      <v-card color="white">
        <v-toolbar color="light-blue" class="primary">
          <v-toolbar-title class="white--text">
            Update keyword
          </v-toolbar-title>
        </v-toolbar>
        <template>
          <v-progress-linear
            color="accent"
            style="margin-top: 0px"
            :indeterminate="isLoading"
          />
        </template>
        <v-card-text>
          <v-text-field v-model="keywordToUpdate.remark" label="Remark" />
          <v-text-field
            v-model="keywordToUpdate.locales.searchCount"
            label="Searches"
          />
          <tag-selector
            :tags.sync="keywordToUpdate.tags"
            :filters="tagFilters"
          />
          <entity-data-provider entity-name="annotation">
            <template slot-scope="props">
              <v-autocomplete
                v-model="keywordToUpdate.annotations"
                :loading="props.isLoading"
                :disabled="props.isLoading"
                :items="props.data"
                multiple
                chips
                deletable-chips
                clearable
                item-text="title"
                item-value="id"
                label="Annotations"
              />
            </template>
          </entity-data-provider>
          <v-flex class="text-xs-center">
            <v-btn @click="addRemarkToKeyword">
              update Keyword
            </v-btn>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
import { cloneDeep } from "lodash";
import TagSelector from "../generic/TagSelector";
import EntityDataProvider from "@/components/legacy/generic/data/EntityDataProvider";

export default {
  name: "add-remark-dialog",
  components: {
    TagSelector,
    EntityDataProvider
  },
  props: {
    addRemark: {
      type: Boolean,
      default: true
    },
    keyword: {
      type: Object
    },
    keywordLocale: {
      type: Object
    },
    localeIndex: {
      type: Number,
      default: 0
    },
    subjectTopicId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    keywordToUpdate: {},
    remark: null,
    tagFilters: {
      type: "keyword"
    },
    isLoading: false
  }),
  computed: {
    internalDialog: {
      get() {
        return this.addRemark;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  watch: {
    addRemark() {
      this.keywordToUpdate = cloneDeep(this.keyword);
      this.keywordToUpdate.locales = cloneDeep(this.keywordLocale);
    }
  },
  methods: {
    async addRemarkToKeyword() {
      await this.$store.dispatch("entities/updateKeyword", {
        entity: this.keywordToUpdate
      });
      // await this.$store.dispatch('entities/fetchSubjectTopic', {
      //     id: this.subjectTopicId,
      //     includes: ['keywordClusters.keywords.locales', 'keywordClusters.keywords.tags'],
      // });
      // this.$parent.callParent();
    }
  }
};
</script>
