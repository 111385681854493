<template>
  <v-card>
    <v-dialog v-model="internalDialog" max-width="400">
      <v-card>
        <v-progress-linear
          v-if="loading"
          :indeterminate="true"
        ></v-progress-linear>
        <v-card-title class="headline">
          Delete content plan?
        </v-card-title>
        <v-card-text
          >Are you sure you want to delete the content plan?</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="internalDialog = false">
            Nevermind
          </v-btn>
          <v-btn color="primary" flat @click.native="removeContentPlan">
            I'm sure
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
import api from "@/api";

export default {
  name: "delete-content-plan-dialog",
  props: {
    deleteDialog: {
      type: Boolean,
      default: false
    },
    contentPlan: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      loading: false
    };
  },
  computed: {
    internalDialog: {
      get() {
        return this.deleteDialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    async removeContentPlan() {
      this.loading = true;
      const removed = await api.contentPlan.remove(this.contentPlan.id);
      this.loading = false;
      if (removed) {
        this.$store.dispatch("snackbar/addItem", {
          text: `Content plan [${this.contentPlan.title}] is removed`,
          color: "success"
        });
      }
      this.$router.push({ name: "content.content-plans" });
    }
  }
};
</script>
