<template>
  <v-btn
    :color="internalColor"
    :flat="flat"
    :icon="icon"
    :loading="isLoading"
    :disabled="isDisabled"
    @click.native="dispatch"
  >
    <v-icon v-if="isError" :color="internalColor" class="pr-3">
      warning
    </v-icon>
    <slot>Save</slot>
  </v-btn>
</template>
<script type="text/babel">
import { isNil, isFunction } from "lodash";
export default {
  name: "v-btn-dispatch",
  props: {
    action: {
      type: String,
      required: true
    },
    payload: {
      type: Object,
      required: false
    },
    color: {
      type: String,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    canRetry: {
      type: Boolean,
      default: true
    },
    callback: {
      type: Function,
      required: false
    }
  },
  data: () => ({
    isDispatching: false,
    isError: false
  }),
  computed: {
    isLoading() {
      return this.isDispatching;
    },
    isDisabled() {
      return this.disabled || this.isDispatching;
    },
    internalColor() {
      if (this.isError) {
        return "warning";
      } else {
        return this.color;
      }
    }
  },
  methods: {
    async dispatch() {
      this.$emit("dispatching", true);
      this.isError = false;
      this.isDispatching = true;
      try {
        const result = await this.$store.dispatch(this.action, this.payload);
        this.isDispatching = false;
        this.$emit("dispatching", false);
        this.$emit("success", result);
        if (!isNil(this.callback) && isFunction(this.callback)) {
          this.callback(result);
        }
      } catch (err) {
        this.isError = true;
        this.isDispatching = false;
        this.$emit("error", err);
        this.$emit("dispatching", false);
      }
    }
  }
};
</script>
