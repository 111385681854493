<template>
  <v-container>
    <v-container fluid>
      <v-layout row>
        <v-flex xs4 mr-1>
          <entity-data-provider
            entity-name="contentPlan"
            :includes="[
              'subjectTopics.keywordClusters.keywords',
              'localization',
              'subjectTopics.localization'
            ]"
          >
            <template slot-scope="props">
              <v-autocomplete
                v-model="contentPlanId"
                :loading="props.isLoading"
                :disabled="props.isLoading"
                :items="props.data"
                clearable
                item-text="title"
                item-value="id"
                label="Content Plan"
              />
            </template>
          </entity-data-provider>
        </v-flex>
        <v-flex xs4 mr-1>
          <v-autocomplete
            v-if="contentPlanSubjectTopics !== null"
            v-model="subjectTopicKeywordClusters"
            :items="subjectTopics"
            clearable
            item-text="title"
            item-value="id"
            label="Subject Topic"
          />
        </v-flex>
        <v-flex xs4 mr-1>
          <v-autocomplete
            v-if="subjectTopicKeywordClusters !== null"
            v-model="selectedKeywordCluster"
            :items="keywordClusters"
            clearable
            item-text="title"
            item-value="id"
            label="Keyword Cluster"
          />
        </v-flex>
      </v-layout>
      <template v-if="selectedKeywordCluster !== null">
        <v-subheader style="padding: 0;">
          Create Keywords:
        </v-subheader>
        <template v-for="keyword in keywords">
          <v-layout row :key="keyword.id">
            <v-flex xs2 mr-1>
              <v-text-field v-model="keyword.title" label="Title" />
            </v-flex>
            <v-flex xs1 mr-1>
              <entity-data-provider entity-name="locale">
                <template slot-scope="props">
                  <v-autocomplete
                    v-model="keyword.localeId"
                    :loading="props.isLoading"
                    :disabled="props.isLoading"
                    :items="props.data"
                    item-text="code"
                    item-value="id"
                    label="Locale"
                  />
                </template>
              </entity-data-provider>
            </v-flex>
            <v-flex xs1 mr-1>
              <v-text-field v-model="keyword.searches" label="Searches" />
            </v-flex>
            <v-flex xs5 mr-1>
              <v-text-field v-model="keyword.remark" label="Remark" />
            </v-flex>
            <v-flex xs3 mr-1>
              <tag-selector :tags.sync="keyword.tags" :filters="tagfilters" />
            </v-flex>
          </v-layout>
        </template>
        <v-btn fab dark small color="pink" @click="pushExtraKeyword">
          <v-icon dark>
            add
          </v-icon>
        </v-btn>
        <v-flex class="text-xs-center" style="min-height: 60px !important;">
          <v-btn outline depressed color="primary" @click="pushFilledKeywords">
            Add Keywords
          </v-btn>
        </v-flex>
      </template>
    </v-container>
  </v-container>
</template>
<script type="text/babel">
import { isNil, forOwn } from "lodash";
import EntityDataProvider from "@/components/legacy/generic/data/EntityDataProvider";
import TagSelector from "../generic/TagSelector";

export default {
  name: "content-plan-add-keywords",
  components: {
    TagSelector,
    EntityDataProvider
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      contentPlanId: null,
      contentPlanSubjectTopics: null,
      subjectTopicKeywordClusters: null,
      selectedKeywordCluster: null,
      keyword: {
        title: null,
        remark: null,
        localeId: null,
        searches: null,
        tags: null
      },
      keywords: [
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        },
        {
          title: null,
          remark: null,
          localeId: null,
          searches: null,
          tags: null
        }
      ],
      keywordsToAdd: [],
      tagfilters: {
        type: "keyword"
      }
    };
  },
  computed: {
    subjectTopics() {
      return this.$store.getters["entities/getEntities"]({
        name: "subjectTopic",
        ids: this.contentPlanSubjectTopics
      });
    },
    subjectTopic() {
      return this.$store.getters["entities/getEntity"]({
        name: "subjectTopic",
        id: this.subjectTopicKeywordClusters
      });
    },
    contentPlan() {
      return this.$store.getters["entities/getEntity"]({
        name: "contentPlan",
        id: this.contentPlanId
      });
    },
    keywordClusters() {
      return this.$store.getters["entities/getEntities"]({
        name: "keywordCluster",
        ids: this.subjectTopic.keywordClusters
      });
    }
  },
  watch: {
    contentPlan: {
      deep: true,
      handler() {
        if (!isNil(this.contentPlan)) {
          this.contentPlanSubjectTopics = this.contentPlan.subjectTopics;
        }
      }
    }
  },
  methods: {
    pushFilledKeywords() {
      let keywordIsEmpty = true;
      this.keywords.forEach(e => {
        forOwn(e, value => {
          if (value !== null) {
            keywordIsEmpty = false;
          }
        });
        if (!keywordIsEmpty) {
          this.keywordsToAdd.push(e);
          keywordIsEmpty = true;
        }
      });
      this.saveKeywords();
    },
    async saveKeywords() {
      await this.$store.dispatch("entities/storeKeywords", {
        contentPlanId: this.contentPlanId,
        keywordClusterId: this.selectedKeywordCluster,
        keywords: this.keywordsToAdd
      });
      this.keywordsToAdd = [];
    },
    pushExtraKeyword() {
      this.keywords.push(this.keyword);
    }
  }
};
</script>
