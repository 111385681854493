<template>
  <v-container>
    <v-subheader>What do you want to create?</v-subheader>
    <v-flex class="text-xs-center" style="min-height: 60px !important;">
      <v-btn-toggle v-model="toggle_exclusive_create_type" depressed>
        <v-btn outline depressed color="primary" @click="setCreateTypeContent">
          Content Plan
        </v-btn>
        <v-btn outline depressed color="primary" @click="setCreateTypeSubject">
          Subject Topic
        </v-btn>
      </v-btn-toggle>
    </v-flex>
    <v-container v-if="createType == 'ContentPlan'" fluid>
      <v-subheader style="padding: 0px;">
        Content Plan Title:
      </v-subheader>
      <locale-textbox
        :specific-locales="specificLocales"
        :label-locales.sync="contentPlanTitle"
      />
    </v-container>
    <v-container v-if="createType == 'SubjectTopic'" fluid>
      <entity-data-provider
        entity-name="contentPlan"
        :includes="['subjectTopics', 'localization']"
      >
        <template slot-scope="props">
          <v-autocomplete
            v-model="selectedContentPlan"
            :loading="props.isLoading"
            :disabled="props.isLoading"
            :items="props.data"
            clearable
            item-text="title"
            item-value="id"
            label="Content Plan"
          />
        </template>
      </entity-data-provider>
      <v-subheader v-if="selectedContentPlan !== null" style="padding: 0px;">
        Subject Topic Title:
      </v-subheader>
      <locale-textbox
        v-if="selectedContentPlan !== null"
        :specific-locales="specificLocales"
        :label-locales.sync="subjectTopicTitle"
      />
    </v-container>
    <v-flex v-if="titleSet" class="text-xs-center">
      <v-btn
        v-if="titleSet"
        outline
        color="primary"
        @click="create(createType)"
      >
        create {{ createType }}
      </v-btn>
    </v-flex>
  </v-container>
</template>
<script type="text/babel">
import { forOwn } from "lodash";
import EntityDataProvider from "@/components/legacy/generic/data/EntityDataProvider";
import LocaleTextbox from "../generic/LocaleTextbox";

export default {
  name: "content-plan-create",
  components: {
    LocaleTextbox,
    EntityDataProvider
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      toggle_exclusive_action_type: null,
      toggle_exclusive_create_type: null,
      toggle_exclusive_update_type: null,
      createType: null,
      selectedContentPlan: null,
      selectedSubjectTopic: null,
      contentPlanSubjectTopics: null,
      contentPlanTitle: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      },
      subjectTopicTitle: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      },
      keywordClusterTitle: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      },
      titleSet: false,
      specificLocales: ["nl_be", "fr_be"]
    };
  },
  computed: {
    subjectTopics() {
      return this.$store.getters["entities/getEntities"]({
        name: "subjectTopic",
        ids: this.contentPlanSubjectTopics
      });
    }
  },
  watch: {
    contentPlanTitle: {
      deep: true,
      handler() {
        this.titleIsSet(this.contentPlanTitle);
      }
    },
    subjectTopicTitle: {
      deep: true,
      handler() {
        this.titleIsSet(this.subjectTopicTitle);
      }
    },
    keywordClusterTitle: {
      deep: true,
      handler() {
        this.titleIsSet(this.keywordClusterTitle);
      }
    }
  },
  methods: {
    setCreateTypeContent() {
      this.createType = "ContentPlan";
    },
    setCreateTypeSubject() {
      this.createType = "SubjectTopic";
    },
    setCreateTypeKeyword() {
      this.createType = "KeywordCluster";
    },
    async create(type) {
      switch (type) {
        case "ContentPlan":
          await this.$store.dispatch("entities/storeContentPlan", {
            entity: this.contentPlanTitle
          });
          break;
        case "SubjectTopic":
          await this.$store.dispatch("entities/storeSubjectTopic", {
            contentPlanId: this.selectedContentPlan,
            entity: this.subjectTopicTitle
          });
          break;
      }
    },
    titleIsSet(object) {
      forOwn(object, value => {
        if (value === "") {
          this.titleSet = false;
        }
      });
      forOwn(object, value => {
        if (value !== null && value !== "") {
          this.titleSet = true;
        }
      });
    }
  }
};
</script>
