<template>
  <tr>
    <td>{{ contentPlan.title }}</td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'content.content-plans.show',
          params: { id: contentPlan.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
    <td></td>
  </tr>
</template>
<script type="text/babel">
export default {
  name: "content-plans-overview-row",
  props: {
    contentPlan: {
      type: Object,
      required: true
    }
  }
};
</script>
