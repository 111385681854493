<template>
  <v-dialog v-model="dialog" max-width="600" lazy>
    <v-btn slot="activator" :icon="!!icon">
      <v-icon v-if="!!icon">
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>
    <v-card>
      <v-card-title class="primary text-uppercase white--text headline">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="!!body">
        {{ body }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          flat
          color="primary"
          :disabled="dispatching"
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn-dispatch
          :action="action"
          :payload="actionPayload"
          @dispatching="onDispatch"
          @success="dialog = false"
        >
          Confirm
        </v-btn-dispatch>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import VBtnDispatch from "./VBtnDispatch";

export default {
  name: "confirm-dispatch-dialog",
  components: { VBtnDispatch },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      required: true
    },
    actionPayload: {
      type: Object,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    body: {
      type: String,
      required: false
    },
    title: {
      type: String,
      default: "Confirm"
    },
    cb: {
      type: Function,
      required: false
    }
  },
  data: () => ({
    dispatching: false
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    onDispatch(isDispatching) {
      this.dispatching = isDispatching;
    }
  }
};
</script>
