<template>
  <div>
    <input
      ref="realInput"
      type="file"
      style="display: none;"
      @change="fileChanged"
    />
    <v-text-field
      :value="fileName"
      :label="label"
      :hint="hint"
      readonly
      persistent-hint
      :data-vv-name="dataVvName"
      :error-messages="errorMessages"
      @click="onClick"
    >
      <v-progress-linear
        v-if="progress"
        slot="progress"
        :value="progress"
        color="accent"
      />
    </v-text-field>
  </div>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "v-upload",
  props: {
    value: {
      type: File,
      required: false
    },
    label: {
      type: String,
      default: "Upload file"
    },
    hint: {
      type: String,
      default: undefined
    },
    progress: {
      type: Number,
      required: false
    },

    dataVvName: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array,
      required: false
    }
  },
  data: () => ({
    fileName: null
  }),
  methods: {
    onClick() {
      this.$refs.realInput.click();
    },
    fileChanged(event) {
      const target = event.target;
      const file = target.files[0];

      if (isNil(file)) {
        this.fileName = null;
        this.$emit("input", null);
      } else {
        this.fileName = file.name;
        this.$emit("input", file);
      }
    }
  }
};
</script>
