<template>
  <v-card>
    <v-data-table
      id="clusterTable"
      ref="table"
      :headers="headers"
      :items="clonedKeywords"
      :pagination.sync="pagination"
      hide-actions
      must-sort
      item-key="id"
    >
      <content-plan-detail-row
        slot="items"
        slot-scope="props"
        :keyword="props.item"
        :selected-locale="selectedLocale"
        :locale-index.sync="localeIndex"
        :subject-topic-id="subjectTopicId"
        :keyword-cluster-id="keywordCluster.id"
      />
    </v-data-table>
    <keyword-cluster-dialog
      :keyword-cluster="keywordCluster"
      :open-dialog="openDialog"
    />
  </v-card>
</template>
<script type="text/babel">
import Sortable from "sortablejs";
import Vue from "vue";
import ContentPlanDetailRow from "./ContentPlanDetailRow";
import KeywordClusterDialog from "./KeywordClusterDialog";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "content-plan-detail-keyword-list",
  components: {
    ContentPlanDetailRow,
    KeywordClusterDialog
  },
  props: {
    keywordClusterId: {
      type: Number,
      required: true
    },
    selectedLocale: {
      type: String,
      required: false
    },
    subjectTopicId: {
      type: Number
    }
  },
  data() {
    return {
      clonedKeywords: [],
      hasLocale: false,
      localeIndex: 0,
      pagination: {
        sortBy: `locales[0].searchCount`,
        descending: true,
        rowsPerPage: -1
      },
      openDialog: false,
      element: null,
      keywordToUpdate: {
        id: null,
        keywordClusterId: null,
        onlyCluster: true
      }
    };
  },
  watch: {
    selectedLocale() {
      this.localeHasKeywords();
    },
    keywordCluster: {
      handler() {
        this.localeHasKeywords();
      },
      deep: true
    }
  },
  mounted() {
    this.setValue();
    new Sortable(this.$refs.table.$el.getElementsByTagName("tbody")[0], {
      draggable: ".sortableRow",
      handle: ".sortHandle",
      group: "cluster",
      onEnd: function(event) {
        let movedKeywordId = parseInt(event.item.getAttribute("value"));
        let movedToCluster = event.to.value;
        let keywordToUpdate = {
          id: movedKeywordId,
          keywordClusterId: movedToCluster,
          onlyCluster: true
        };
        let url = window.Ziggy.namedRoutes["api.keywords.index"].uri;
        const prefix = `${location.protocol}//${location.host}/`;
        if (!url.startsWith(prefix)) {
          url = `${prefix}${url}`;
        }
        url = `${url}/${movedKeywordId}`;
        Vue.$http.put(url, keywordToUpdate);
      }
    });
  },
  created() {
    this.localeHasKeywords();
  },
  computed: {
    ...mapGetters(["hasRolesOrPermissions"]),
    keywordCluster() {
      return this.$store.getters["entities/getEntity"]({
        name: "keywordCluster",
        id: this.keywordClusterId
      });
    },
    keywords() {
      return this.$store.getters["entities/getEntities"]({
        name: "keyword",
        ids: this.keywordCluster.keywords
      });
    },
    keywordLocale(id) {
      return this.$store.getters["entities/getEntity"]({
        name: "keywordLocale",
        id: id
      });
    },
    headers() {
      return [
        { text: "", sortable: false },
        { text: "Keywords", align: "left", value: "title", sortable: false },
        { text: "Tags", align: "left", value: "tags", sortable: false },
        {
          text: this.selectedLocale + " searches",
          value: `locales[${this.localeIndex}].searchCount`
        },
        { text: this.selectedLocale + " position", sortable: false },
        { text: "url", value: "url", sortable: false },
        {
          text: "Remove",
          value: "remove",
          sortable: false,
          rolesOrPermissions: ["keyword_detach"]
        },
        { text: "Action", value: "action", sortable: false }
      ].filter(header => this.hasRolesOrPermissions(header.rolesOrPermissions));
    }
  },
  methods: {
    localeHasKeywords() {
      this.clonedKeywords = [];
      this.hasLocale = false;
      this.keywords.forEach(e => {
        this.hasLocale = false;
        let locales = this.$store.getters["entities/getEntities"]({
          name: "keywordLocale",
          ids: e.locales
        });
        if (locales) {
          locales.forEach(e => {
            if (e.locale_code === this.selectedLocale) {
              this.hasLocale = true;
            }
          });
        }
        if (this.hasLocale === true) {
          e.locales = locales;
          this.clonedKeywords.push(e);
        }
      });
    },
    openDialogClusterDetail() {
      this.openDialog = !this.openDialog;
    },
    setValue() {
      let s = this.$refs.table.$el.getElementsByTagName("tbody")[0];
      s.value = this.keywordCluster.id;
    },
    async updateThisKeyword(movedKeywordId, movedToCluster) {
      this.keywordToUpdate.id = movedKeywordId;
      this.keywordToUpdate.keywordClusterId = movedToCluster;
      await this.$store.dispatch("entities/updateKeyword", {
        entity: this.keywordToUpdate
      });
    }
  }
};
</script>
