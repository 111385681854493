<template>
  <v-flex>
    <v-layout row>
      <v-flex xs1>
        <v-subheader style="padding: 0;">
          Locales:
        </v-subheader>
      </v-flex>
      <v-flex xs11>
        <v-btn-toggle v-model="locales" depressed multiple>
          <v-btn outline depressed color="primary" value="nl_be">
            nl_be
          </v-btn>
          <v-btn outline depressed color="primary" value="nl_nl">
            nl_nl
          </v-btn>
          <v-btn outline depressed color="primary" value="fr_be">
            fr_be
          </v-btn>
          <v-btn outline depressed color="primary" value="fr_fr">
            fr_fr
          </v-btn>
          <v-btn outline depressed color="primary" value="en_gb">
            en_gb
          </v-btn>
          <v-btn outline depressed color="primary" value="de_de">
            de_de
          </v-btn>
          <v-btn outline color="pink" value="ALL">
            ALL
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
    <v-text-field
      v-for="localeToggle in localesToggle"
      :key="localeToggle"
      v-model="text[localeToggle]"
      :label="localeToggle"
      @input="$emit('update:labelLocales', text)"
    />
  </v-flex>
</template>
<script type="text/babel">
import { isNil, clone } from "lodash";

export default {
  name: "locale-textbox",
  mixins: [],
  props: {
    specificLocales: {
      type: Array,
      required: false
    },
    userDefaultLocales: {
      type: Array,
      required: false
    },
    labelLocales: {
      required: false
    },
    localesText: {
      type: Object,
      required: false
    }
  },
  data: () => {
    return {
      localesToggle: ["nl_be"],
      test: null,
      checkedLocales: false,
      text: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      }
    };
  },
  computed: {
    locales: {
      get: function() {
        this.checkLocales();
        return this.localesToggle;
      },
      set: function(newValue) {
        this.localesToggle = newValue;
        if (this.localesToggle.includes("ALL")) {
          this.localesToggle = [
            "nl_be",
            "nl_nl",
            "fr_be",
            "fr_fr",
            "en_gb",
            "de_de"
          ];
        }
        return this.localesToggle;
      }
    }
  },
  watch: {
    localesText: {
      deep: true,
      handler() {
        this.text = clone(this.localesText);
      }
    }
  },
  created() {
    if (!isNil(this.localesText)) {
      this.text = clone(this.localesText);
    }
  },
  methods: {
    checkLocales() {
      if (!this.checkedLocales) {
        if (this.specificLocales !== undefined) {
          this.localesToggle = this.specificLocales;
        }
        if (this.userDefaultLocales !== undefined) {
          this.localesToggle = this.userDefaultLocales;
        }
        this.checkedLocales = true;
      }
    }
  }
};
</script>
