<template>
  <v-card color="white">
    <v-card-title class="headline">
      Upload content plan
    </v-card-title>
    <v-progress-linear
      v-model="progress"
      :color="progress === 0 ? 'white' : 'accent'"
      size="2"
      class="my-0"
    />
    <v-card-text>
      <v-text-field
        ref="fileUploadTextField"
        :value="uploadFileName"
        prepend-icon="attach_file"
        :single-line="!hasUploadFile"
        :required="fileUploadRequired"
        :disabled="fileUploadDisabled"
        accept="text/csv"
        :label="uploadFileLabel"
        @click.native="onFocusFileUpload"
      />
      Testing: {{ progress }}
      <input
        ref="fileInput"
        type="file"
        accept=".csv"
        style="display: none;"
        @change="onFileChange"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn flat :disabled="isUploading" @click.native="closeUploadDialog">
        Nevermind
      </v-btn>
      <v-btn
        color="primary"
        flat
        :disabled="!hasUploadFile"
        :loading="isUploading"
        @click.native="upload"
      >
        I'm sure
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script type="text/babel">
import Vue from "vue";
import { isNil } from "lodash";

export default {
  name: "content-plans-overview-uploader",
  data() {
    return {
      url: `api/v1/content-plans`,
      isUploadOpen: false,
      isUploading: false,
      uploadFile: null,
      fileUploadRequired: null,
      fileUploadDisabled: null,
      progress: 0
    };
  },
  computed: {
    hasUploadFile() {
      return !isNil(this.uploadFile);
    },
    uploadFileName() {
      return this.hasUploadFile ? this.uploadFile.name : "";
    },
    uploadFileLabel() {
      if (!this.hasUploadFile) {
        return "Select a file to upload";
      } else {
        if (this.uploadFile.size > 1048576) {
          return `${Math.floor(this.uploadFile.size / 1048576)}MB`;
        } else if (this.uploadFile.size > 1024) {
          return `${Math.floor(this.uploadFile.size / 1024)}KB`;
        } else {
          return `${Math.floor(this.uploadFile.size)}B`;
        }
      }
    }
  },
  methods: {
    closeUploadDialog() {
      this.$emit("input", false);
    },
    onFocusFileUpload() {
      if (!this.fileUploadDisabled) {
        this.$refs.fileInput.click();
      }
    },
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];
      this.uploadFile = file;
    },
    upload() {
      const formData = new FormData();
      formData.append("upload", this.uploadFile, this.uploadFile.name);

      Vue.$http
        .post(
          `${process.env.VUE_APP_API_ROOT}/api/v1/content-plans/import`,
          formData,
          {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: progressEvent => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }
          }
        )
        .then(() => {
          this.progress = 0;
          this.closeUploadDialog();
        })
        .catch(() => {
          this.progress = 0;
        });
    }
  }
};
</script>
