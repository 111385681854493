<template>
  <tr class="sortableRow" :value="keyword.id">
    <td class="px-1" style="width: 0.1%">
      <v-btn style="cursor: move" icon class="sortHandle">
        <v-icon>drag_handle</v-icon>
      </v-btn>
    </td>
    <td style="width: 25%;" @click="showTopUrls = !showTopUrls">
      <v-badge color="#C0C0C0">
        <v-tooltip v-if="keywordHasRemark()" slot="badge" bottom>
          <span slot="activator"> i </span>{{ keyword.remark }}
        </v-tooltip>
        {{ keyword.title }}
      </v-badge>
    </td>
    <td v-if="keyword.tags !== null" style="width: 10%;">
      <template v-for="(tag, index) in keyword.tags">
        <v-chip
          v-if="index < 2 && tag !== undefined"
          :key="index"
          :color="tag.color"
          label
          small
        >
          {{ tag.name }}
        </v-chip>
      </template>
      <!--<v-tooltip v-if="keyword.tags.length > 2" bottom>-->
      <!--<v-chip slot="activator" v-html="'...'" />-->
      <!--<span>-->
      <!--<template v-for="(tag, index) in keyword.tags">-->
      <!--<v-chip-->
      <!--v-if="tag !== undefined"-->
      <!--:key="index"-->
      <!--:color="tag.color"-->
      <!--label-->
      <!--&gt;-->
      <!--{{ tag.name }}-->
      <!--</v-chip>-->
      <!--</template>-->
      <!--</span>-->
      <!--</v-tooltip>-->
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keyword.locales[localeIndex].searchCount }}
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keyword.locales[localeIndex].position }}
    </td>
    <td
      v-if="showTopUrls === false"
      style="width: 30%;"
      @click="showTopUrls = !showTopUrls"
    >
      {{ bulditTopUrl(keyword) }}
    </td>
    <td v-if="showTopUrls === true" style="width: 25%;">
      <template v-for="(value, index) in keyword.locales[localeIndex].payload">
        <ul v-if="value != null" style="list-style-type: none;" :key="index">
          <!-- @TODO fix this nesting-->
          <a :href="value.url" target="_blank">
            <li v-if="isNoBulditLink(value, index)">
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="value.position === keyword.locales[localeIndex].position"
              style="background-color: #FFD817"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="isBulditLink(value, index)"
              style="background-color: #A9A9A9"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
        </ul>
      </template>
    </td>
    <td v-if="hasRolesOrPermissions(['keyword_detach'])">
      <confirm-dispatch-dialog
        v-model="detachDialog"
        action="entities/detachKeyword"
        :action-payload="{ keywordId: keyword.id }"
        icon="far fa-fw fa-times"
        body="Are you sure you want to detach this keyword from this keyword cluster?"
      />
    </td>
    <td v-if="userNeededPermissions(['edit_content_plans'])" style="width: 5%;">
      <v-btn icon @click="openRemarkDialog">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </td>
    <add-remark-dialog
      :keyword="keyword"
      :add-remark="addRemark"
      :locale-index="localeIndex"
      :subject-topic-id="subjectTopicId"
    />
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import AddRemarkDialog from "./AddRemarkDialog";
import RolesAndPermissionsMixin from "../mixins/RolesAndPermissionsMixin";
import { createNamespacedHelpers } from "vuex";
import ConfirmDispatchDialog from "../generic/ConfirmDispatchDialog";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "content-plan-detail-row",
  components: {
    ConfirmDispatchDialog,
    AddRemarkDialog
  },
  mixins: [RolesAndPermissionsMixin],
  props: {
    keyword: {
      type: Object,
      required: false
    },
    keywordClusterId: {
      type: Number
    },
    selectedLocale: {
      type: String,
      required: false
    },
    localeIndex: {
      type: Number
    },
    subjectTopicId: {
      type: Number
    }
  },
  data() {
    return {
      showTopUrls: false,
      addRemark: false,
      emptyLocale: {
        highestBulditArticleId: null,
        highestBulditWebsiteId: null,
        id: null,
        isUpdateFailed: null,
        locale_code: null,
        locale_id: null,
        payload: null,
        position: null,
        searchCount: null
      },
      detachDialog: false
    };
  },
  watch: {
    selectedLocale() {
      this.getLocale();
    }
  },
  created() {
    this.getLocale();
  },
  computed: {
    ...mapGetters(["hasRolesOrPermissions"])
  },
  methods: {
    bulditTopUrl(keyword) {
      if (keyword.locales[this.localeIndex].payload === null) {
        return;
      }
      const selectedPayloadRow =
        keyword.locales[this.localeIndex].payload[
          keyword.locales[this.localeIndex].position
        ];
      if (isNil(selectedPayloadRow)) {
        return;
      }
      return keyword.locales[this.localeIndex].payload[
        keyword.locales[this.localeIndex].position - 1
      ].url.replace(/^(https?:\/\/)?(www\.)?/, "");
    },
    // indexCheck (keyword) {
    //     keyword.locales[this.localeIndex].payload.forEach((e, index) => {
    //         if (index >= 10 && e.isBuldit === false && e === null) {
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     })
    // },
    openRemarkDialog() {
      this.addRemark = !this.addRemark;
    },
    getLocale() {
      this.keyword.locales.forEach((e, index) => {
        if (e.locale_code === this.selectedLocale) {
          this.$emit("update:localeIndex", index);
        }
      });
    },
    regexUrl(url) {
      if (!isNil(url)) {
        return url.replace(/^(https?:\/\/)?(www\.)?/, "");
      }
    },
    isBulditLink(value, index) {
      if (
        value.isBuldit === true &&
        value.position !== this.keyword.locales[this.localeIndex].position &&
        index < 10
      ) {
        return true;
      }
    },
    isNoBulditLink(value, index) {
      if (
        value.isBuldit === false &&
        value.position !== this.keyword.locales[this.localeIndex].position &&
        index < 10
      ) {
        return true;
      }
    },
    keywordHasRemark() {
      if (this.keyword.remark !== "" && this.keyword.remark !== null) {
        return true;
      }
    },
    callParent() {
      this.$parent.$parent.localeHasKeywords();
    }
  }
};
</script>
<style scoped>
ul {
  padding-left: 0 !important;
  overflow-x: hidden;
  white-space: nowrap;
  width: 18vw;
  text-overflow: ellipsis;
}

li {
  display: inline;
}
</style>
