<template>
  <v-layout>
    <v-flex>
      <v-toolbar color="primary" dark>
        <v-icon>chrome_reader_mode</v-icon>
        <v-toolbar-title>{{ editableEntity.title }}</v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="contentPlanSearch"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          @keyup.enter="search"
        />
        <v-btn
          v-if="hasRolesOrPermissions('content_plan_delete')"
          icon
          @click="deleteDialog = true"
          @close="deleteDialog = false"
        >
          <v-icon>far fa-trash</v-icon>
        </v-btn>
      </v-toolbar>
      <v-flex>
        <v-card height="10vh" color="blue lighten-5">
          <v-flex ml4 mt3 xs4 d-flex>
            <v-select-locale v-model="selectedLocale" item-value="code" />
          </v-flex>
        </v-card>
      </v-flex>
      <v-flex v-if="translateTopic">
        <v-card color="white">
          <v-flex xs11>
            <locale-textbox
              style="padding-left: 0;"
              :label-locales.sync="subjectTopicLocales"
              :locales-text="subjectTopicLocales"
            />
          </v-flex>
          <v-flex class="text-xs-center">
            <v-btn
              outline
              depressed
              color="primary"
              @click="updateSubjectTopic"
            >
              Update Subject Topic
            </v-btn>
            <v-btn
              outline
              depressed
              color="primary"
              @click="translateTopic = !translateTopic"
            >
              Close
            </v-btn>
          </v-flex>
        </v-card>
      </v-flex>
      <v-layout row>
        <v-flex v-if="!isSearched" xs2>
          <v-card height="70vh">
            <v-toolbar color="primary">
              <v-toolbar-title style="color: white">
                Subject Topics
              </v-toolbar-title>
            </v-toolbar>
            <div style="max-height: 65vh;" class="scroll-y">
              <v-list dense>
                <template v-for="(subjectTopic, index) in subjectTopics">
                  <v-list-tile :key="index" avatar ripple>
                    <v-list-tile-content @click="getLocales(subjectTopic.id)">
                      <v-list-tile-title
                        >{{ subjectTopic.title }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn
                        icon
                        small
                        ripple
                        @click="translatSubjectTopic(subjectTopic.id)"
                      >
                        <v-icon
                          style="font-size: 15px !important;"
                          color="grey lighten-1"
                        >
                          far fa-pen
                        </v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider
                    v-if="index < subjectTopics.length"
                    :key="`divider-${index}`"
                  />
                </template>
              </v-list>
            </div>
          </v-card>
        </v-flex>
        <v-flex v-if="!isSearched" xs10>
          <v-card height="70vh">
            <v-toolbar color="primary">
              <v-toolbar-title style="color: white">
                Keywords
              </v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="triggerExpand">
                <v-icon style="color: white">
                  {{ expandToBoolean ? "unfold_less" : "unfold_more" }}
                </v-icon>
              </v-btn>
            </v-toolbar>
            <div
              v-if="keywordsShown === true"
              style="max-height: 65vh;"
              class="scroll-y"
            >
              <v-flex
                v-for="keywordClusterId in subjectTopic.keywordClusters"
                :key="keywordClusterId"
              >
                <content-plan-detail-keyword-list
                  :keyword-cluster-id="keywordClusterId"
                  :selected-locale="selectedLocale"
                  :subject-topic-id="subjectTopicId"
                />
              </v-flex>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
      <v-flex v-if="isSearched" xs12>
        <v-card height="70vh">
          <v-toolbar color="primary">
            <v-toolbar-title style="color: white">
              Search Results: {{ contentPlanSearch }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="triggerExpandSearched">
              <v-icon style="color: white">
                {{ expandToBooleanSearch ? "unfold_less" : "unfold_more" }}
              </v-icon>
            </v-btn>
            <v-btn icon @click="closeSearchResults">
              <v-icon style="color: white">
                close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <div style="max-height: 65vh;" class="scroll-y">
            <v-flex class="text-xs-center">
              <v-progress-circular
                v-if="isSearching === true"
                indeterminate
                :size="100"
                color="primary"
              />
            </v-flex>
            <v-data-table
              :headers="headers"
              :items="searchedKeywords"
              must-sort
              hide-actions
              item-key="id"
            >
              <content-plan-detail-row-search
                slot="items"
                slot-scope="props"
                :keyword="props.item"
                :selected-locale="selectedLocale"
              />
            </v-data-table>
          </div>
        </v-card>
      </v-flex>
    </v-flex>
    <v-btn fab bottom right dark fixed color="pink" @click="exportCsv()">
      <v-icon class="far fa-file-download" />
    </v-btn>
    <delete-content-plan-dialog
      :content-plan="editableEntity"
      :delete-dialog="deleteDialog"
      @close="deleteDialog = false"
    />
  </v-layout>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import CrudMixin from "@/components/legacy/generic/crud/CrudMixin.vue";
import ContentPlanDetailKeywordList from "./ContentPlanDetailsKeywordList";
import ContentPlanDetailRowSearch from "./ContentPlanDetailRowSearch";
import LocaleTextbox from "@/components/generic/LocaleTextbox";
import FileSaver from "file-saver";
import VSelectLocale from "@/components/locale/VSelectLocale";
import Vue from "vue";
import { createApiUrl } from "@/api/url";
import DeleteContentPlanDialog from "./DeleteContentPlanDialog";

export default {
  name: "content-plan-details",
  components: {
    DeleteContentPlanDialog,
    VSelectLocale,
    ContentPlanDetailKeywordList,
    ContentPlanDetailRowSearch,
    LocaleTextbox
  },
  mixins: [CrudMixin],
  data: () => {
    return {
      entityName: "contentPlan",
      includes: [],
      keywordsShown: false,
      selectedSubjectTopicId: null,
      keywordClusterId: null,
      selectedKeywordClusterId: null,
      showTopUrls: false,
      test: 1,
      allexpanded: false,
      expansions: {},
      expansionsSearch: {},
      expandToBoolean: false,
      expandToBooleanSearch: false,
      contentPlanSearch: null,
      filterQuickSearchTimeout: undefined,
      keywordIds: [],
      keywordClusterIds: [],
      isSearched: false,
      isSearching: false,
      selectedLocale: "nl_be",
      clonedKeywords: [],
      pagination: {},
      subjectTopicId: null,
      translateTopic: false,
      subjectTopicLocales: {
        nl_be: null,
        nl_nl: null,
        fr_be: null,
        fr_fr: null,
        en_gb: null,
        de_de: null
      },
      selectedSubjectToUpdateId: null,
      deleteDialog: false
    };
  },
  computed: {
    subjectTopics() {
      return this.$store.getters["entities/getEntities"]({
        name: "subjectTopic",
        ids: this.editableEntity.subjectTopics
      });
    },
    subjectTopic() {
      return this.$store.getters["entities/getEntity"]({
        name: "subjectTopic",
        id: this.selectedSubjectTopicId
      });
    },
    keywordClusters() {
      return this.$store.getters["entities/getEntities"]({
        name: "keywordCluster",
        ids: this.subjectTopic.keywordClusters
      });
    },
    keywordCluster() {
      if (!isNil(this.selectedKeywordClusterId)) {
        return this.$store.getters["entities/getEntity"]({
          name: "keywordCluster",
          id: this.selectedKeywordClusterId
        });
      } else {
        return undefined;
      }
    },
    filteredKeywords() {
      return this.$store.getters["entities/getEntities"]({
        name: "keyword",
        ids: this.keywordIds
      });
    },
    filteredKeywordClusters() {
      return this.$store.getters["entities/getEntities"]({
        name: "keywordCluster",
        ids: this.keywordClusterIds
      });
    },
    locales() {
      return this.$store.getters["entities/getEntities"]({ name: "locale" });
    },
    searchedKeywords() {
      let result = this.$store.getters["entities/getEntities"]({
        name: "keyword",
        ids: this.keywordIds
      });
      return result;
    },
    headers() {
      let headers = [
        { text: "Keywords", align: "left", value: "title" },
        {
          text: this.selectedLocale + " searches",
          value: "locales[0].searchCount"
        },
        { text: this.selectedLocale + " position", sortable: false },
        { text: "url", value: "url", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ];
      return headers;
    }
  },
  watch: {
    selectedLocale() {
      if (this.isSearched) {
        this.search();
      }
    }
  },
  methods: {
    getKeywordsClusters(subjectId) {
      this.selectedSubjectTopicId = subjectId;
      this.keywordsShown = true;
    },
    getKeywordsClusterId(id) {
      this.selectedKeywordClusterId = id;
    },
    async getLocales(subjectId) {
      this.subjectTopicId = subjectId;
      await this.$store.dispatch("entities/fetchSubjectTopic", {
        id: subjectId,
        includes: ["keywordClusters.keywords.locales"]
      });
      this.selectedSubjectTopicId = subjectId;
      this.keywordsShown = true;
      this.expansion = {};
      this.keywordClusters.forEach(keywordCluster => {
        this.expansions[keywordCluster.id] = 1;
      });
    },
    bulditTopUrl(keyword) {
      const selectedPayloadRow =
        keyword.locales[0].payload[keyword.locales[0].position];
      if (isNil(selectedPayloadRow)) {
        return;
      }
      return keyword.locales[0].payload[keyword.locales[0].position - 1].url;
    },
    triggerExpand() {
      this.expandToBoolean = !this.expandToBoolean;
      this.expansion = {};
      if (this.expandToBoolean === true) {
        this.keywordClusters.forEach(keywordCluster => {
          this.expansions[keywordCluster.id] = 0;
        });
      } else {
        this.keywordClusters.forEach(keywordCluster => {
          this.expansions[keywordCluster.id] = 1;
        });
      }
    },
    triggerExpandSearched() {
      this.expandToBooleanSearch = !this.expandToBooleanSearch;
      this.expansion = {};
      if (this.expandToBooleanSearch === true) {
        this.filteredKeywordClusters.forEach(keywordCluster => {
          this.expansionsSearch[keywordCluster.id] = 0;
        });
      } else {
        this.filteredKeywordClusters.forEach(keywordCluster => {
          this.expansionsSearch[keywordCluster.id] = 1;
        });
      }
    },
    async search() {
      this.isSearching = true;
      this.isSearched = true;
      const payload = await this.$store.dispatch("entities/getSearchResult", {
        searchQuery: this.contentPlanSearch,
        locale: this.selectedLocale
      });
      this.keywordIds = payload.keywordIds;
      this.keywordClusterIds = payload.keywordClusterIds;
      this.filteredKeywordClusters.forEach(keywordCluster => {
        this.expansionsSearch[keywordCluster.id] = 1;
      });
      this.isSearching = false;
    },
    closeSearchResults() {
      this.isSearched = false;
    },
    translatSubjectTopic(topicId) {
      let subjectTopic = this.$store.getters["entities/getEntity"]({
        name: "subjectTopic",
        id: topicId
      });
      this.subjectTopicLocales = subjectTopic.localization;
      this.selectedSubjectToUpdateId = topicId;
      this.translateTopic = true;
    },
    async updateSubjectTopic() {
      await this.$store.dispatch("entities/updateSubjectTopic", {
        subjectTopicId: this.selectedSubjectToUpdateId,
        entity: this.subjectTopicLocales
      });
    },
    async exportCsv() {
      const url = createApiUrl(
        `api/v1/content-plans/${this.editableEntity.id}/export`
      );
      await Vue.$http.get(url).then(response => {
        const contentDisposition = response.headers["content-disposition"];
        const regex = /attachment; filename=(\w*.\w*)/;
        const fileName = contentDisposition.match(regex)[1];

        // Let the user save the file.
        const blob = new Blob([response.data], { type: "text/csv" });
        FileSaver.saveAs(blob, fileName);
      });
    },
    hasRolesOrPermissions(permission) {
      return this.$store.getters["auth/hasRolesOrPermissions"](permission);
    }
  }
};
</script>
