<template>
  <div>
    <generic-overview
      :route="route"
      :dialog="dialog"
      searchable
      :dialog-content-class="dialogContentClass"
      :filters="filters"
      :actions="actions"
      :focused="true"
      icon="business"
      title="Content Plans"
    >
      <div slot="content">
        <table-data-provider
          ref="contentPlanTable"
          :url="url"
          :pagination="pagination"
          :filters="filters"
          :includes="includes"
          :entity-name="entityName"
          :schema="schema"
        >
          <v-data-table
            slot-scope="props"
            :headers="headers"
            :items="props.items"
            :pagination.sync="pagination"
            :total-items="props.totalItems"
            :rows-per-page-items="[50, 100]"
            disable-initial-sort
            disable-page-reset
            :loading="props.isLoading"
            class="elevation-1"
          >
            <content-plans-overview-row
              slot="items"
              slot-scope="props"
              :content-plan="props.item"
            />
          </v-data-table>
        </table-data-provider>
      </div>
      <router-view slot="dialog" />
    </generic-overview>

    <upload-content-plan-dialog v-model="isUploadShown" />
  </div>
</template>
<script type="text/babel">
import TableDataProvider from "@/components/legacy/generic/data/TableDataProvider";
import GenericOverview from "@/components/generic/overview/Overview";
import EntityOverviewMixin from "@/components/legacy/generic/entity/EntityOverviewMixin.vue";
import ContentPlansOverviewRow from "@/components/content-plan/ContentPlansOverviewRow";
import { contentPlan as schema } from "@/store/schema";
import RolesAndPermissionsMixin from "@/components/mixins/RolesAndPermissionsMixin";
import FileSaver from "file-saver";
import api from "@/api";
import UploadContentPlanDialog from "@/components/content-plan/UploadContentPlanDialog";

export default {
  name: "bo-man-companies-overview",
  components: {
    UploadContentPlanDialog,
    ContentPlansOverviewRow,
    TableDataProvider,
    GenericOverview
  },
  mixins: [EntityOverviewMixin, RolesAndPermissionsMixin],
  data() {
    return {
      route: "content.content-plans",
      routeUpload: "content.content-plans.upload",
      routeDetails: "content.content-plans.show",
      isFiltersOpen: false,
      isSidebarOpen: false,
      url: `api/v1/content-plans`,
      actions: [
        {
          icon: "far fa-fw fa-plus fa-xs",
          color: "pink",
          to: { name: "content.content-plans.actions" },
          tooltip: "Create new content plan",
          rolesOrPermissions: ["content_plan_create"]
        },
        {
          icon: "far fa-fw fa-file-download fa-sm",
          color: "blue",
          clickFn: this.downloadTemplate,
          tooltip: "Download content plan template",
          rolesOrPermissions: ["content_plan_export"]
        },
        {
          icon: "far fa-fw fa-file-upload fa-lg",
          color: "green",
          clickFn: this.showContentPlanUpload,
          tooltip: "Upload content plan import csv",
          rolesOrPermissions: ["content_plan_import"]
        }
      ],
      totalItems: 0,
      items: [],
      loading: true,
      pagination: {
        sortBy: "title",
        descending: true
      },
      headers: [
        { text: "Title", value: "title" },
        { text: "Actions", align: "right", sortable: false, width: "100px" },
        { text: "Actions", align: "right", sortable: false, width: "100px" }
      ],
      filters: {
        search: null
      },
      isUploadOpen: false,
      contentUpload: true,
      entityName: "contentPlan",

      isUploadShown: false
    };
  },
  computed: {
    schema() {
      return schema;
    },
    dialogContentClass() {
      return "content-plan-actions-dialog";
    }
  },
  methods: {
    showContentPlanUpload() {
      this.isUploadShown = true;
    },
    async downloadTemplate() {
      const { data, fileName } = await api.contentPlan.template();

      // Let the user save the file.
      const blob = new Blob([data], { type: "text/csv" });
      FileSaver.saveAs(blob, `${fileName}.csv`);
    },
    async importCsv(file) {
      await api.contentPlan.uploadImport(file);
      this.$store.dispatch("snackbar/addItem", {
        text: `Your content plan import file was successfully uploaded. You will be notified once it's processed.`,
        color: "success"
      });
    }
  }
};
</script>
<style>
.v-dialog.content-plan-actions-dialog {
  height: auto;
  width: 60%;
}
</style>
