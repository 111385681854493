<template>
  <tr>
    <td style="width: 35%;" @click="showTopUrls = !showTopUrls">
      <v-badge color="#C0C0C0">
        <v-tooltip v-if="keywordHasRemark()" slot="badge" bottom>
          <span slot="activator"> i </span>{{ keyword.remark }} </v-tooltip
        >{{ keyword.title }}
      </v-badge>
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keyword.locales[localeIndex].searchCount }}
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keyword.locales[localeIndex].position }}
    </td>
    <td
      v-if="showTopUrls === false"
      style="width: 30%;"
      @click="showTopUrls = !showTopUrls"
    >
      {{ bulditTopUrl(keyword) }}
    </td>
    <td v-if="showTopUrls === true" style="width: 25%;">
      <template v-for="value in keyword.locales[localeIndex].payload">
        <ul v-if="value != null" style="list-style-type: none;" :key="value">
          <!-- @TODO fix this nesting-->
          <a :href="value.url" target="_blank">
            <li v-if="isNoBulditLink(value)">
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="value.position === keyword.locales[localeIndex].position"
              style="background-color: #FFD817"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li v-if="isBulditLink(value)" style="background-color: #A9A9A9">
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
        </ul>
      </template>
    </td>
    <td style="width: 5%;">
      <v-btn icon @click="openRemarkDialog">
        <v-icon>add</v-icon>
      </v-btn>
    </td>
    <add-remark-dialog :keyword="keyword" :add-remark="addRemark" />
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import AddRemarkDialog from "./AddRemarkDialog";

export default {
  name: "content-plan-detail-row-search",
  components: { AddRemarkDialog },
  props: {
    keyword: {
      type: Object,
      required: false
    },
    keywordCluster: {
      type: Object,
      required: false
    },
    selectedLocale: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showTopUrls: false,
      addRemark: false,
      emptyLocale: {
        highestBulditArticleId: null,
        highestBulditWebsiteId: null,
        id: null,
        isUpdateFailed: null,
        locale_code: null,
        locale_id: null,
        payload: null,
        position: null,
        searchCount: null
      },
      hasNoLocale: false
    };
  },
  computed: {},
  watch: {
    selectedLocale() {
      this.getLocale();
    }
  },
  created() {
    this.getLocale();
  },
  methods: {
    bulditTopUrl(keyword) {
      if (keyword.locales[this.localeIndex].payload === null) {
        return;
      }
      const selectedPayloadRow =
        keyword.locales[this.localeIndex].payload[
          keyword.locales[this.localeIndex].position
        ];
      if (isNil(selectedPayloadRow)) {
        return;
      }
      return keyword.locales[this.localeIndex].payload[
        keyword.locales[this.localeIndex].position - 1
      ].url.replace(/^(https?:\/\/)?(www\.)?/, "");
    },
    openRemarkDialog() {
      this.addRemark = !this.addRemark;
    },
    getLocale() {
      this.keyword.locales.forEach((e, index) => {
        if (e.locale_code === this.selectedLocale) {
          this.localeIndex = index;
        }
      });
    },
    regexUrl(url) {
      if (!isNil(url)) {
        return url.replace(/^(https?:\/\/)?(www\.)?/, "");
      }
    },
    isBulditLink(value) {
      if (
        value.isBuldit === true &&
        value.position !== this.keyword.locales[this.localeIndex].position
      ) {
        return true;
      }
    },
    isNoBulditLink(value) {
      if (
        value.isBuldit === false &&
        value.position !== this.keyword.locales[this.localeIndex].position
      ) {
        return true;
      }
    },
    keywordHasRemark() {
      if (this.keyword.remark !== "") {
        return true;
      }
    }
  }
};
</script>
<style scoped>
ul {
  padding-left: 0 !important;
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
}
li {
  display: inline;
}
</style>
