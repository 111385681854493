<template>
  <tr>
    <td v-if="keyword.tags !== null" style="width: 10%;">
      <v-chip label small>
        {{ keyword.locale.code }}
      </v-chip>
    </td>
    <td style="width: 25%;" @click="showTopUrls = !showTopUrls">
      <v-badge color="#C0C0C0">
        <v-tooltip v-if="keywordHasRemark()" slot="badge" bottom>
          <span slot="activator"> i </span>{{ keyword.remark }} </v-tooltip
        >{{ keyword.title }}
      </v-badge>
    </td>
    <td v-if="keyword.tags !== null" style="width: 10%;">
      <template v-for="(tag, index) in keyword.tags">
        <v-chip
          v-if="index < 2 && tag !== undefined"
          :key="index"
          :color="tag.color"
          label
          small
        >
          {{ tag.name }}
        </v-chip>
      </template>
      <v-tooltip v-if="keyword.tags.length > 2" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <template v-for="(tag, index) in keyword.tags">
            <v-chip
              v-if="tag !== undefined"
              :key="index"
              :color="tag.color"
              label
            >
              {{ tag.name }}
            </v-chip>
          </template>
        </span>
      </v-tooltip>
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keyword.searchCount }}
    </td>
    <td style="width: 15%;" @click="showTopUrls = !showTopUrls">
      {{ keyword.position }}
    </td>
    <td
      v-if="showTopUrls === false"
      style="width: 30%;"
      @click="showTopUrls = !showTopUrls"
    >
      {{ bulditTopUrl(keyword) }}
    </td>
    <td v-if="showTopUrls === true" style="width: 25%;">
      <template v-for="(value, index) in keyword.payload">
        <ul v-if="value != null" style="list-style-type: none;" :key="index">
          <!-- @TODO fix this nesting-->
          <a :href="value.url" target="_blank">
            <li v-if="isNoBulditLink(value, index)">
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="value.position === keyword.position"
              style="background-color: #FFD817"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
          <a :href="value.url" target="_blank">
            <li
              v-if="isBulditLink(value, index)"
              style="background-color: #A9A9A9"
            >
              {{ value.position }}. {{ regexUrl(value.url) }}
            </li>
          </a>
        </ul>
      </template>
    </td>
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import RolesAndPermissionsMixin from "../mixins/RolesAndPermissionsMixin";

export default {
  name: "keyword-cluster-detail-row",
  mixins: [RolesAndPermissionsMixin],
  props: {
    keyword: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      showTopUrls: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    bulditTopUrl(keyword) {
      if (keyword.payload === null) {
        return;
      }
      const selectedPayloadRow = keyword.payload[keyword.position];
      if (isNil(selectedPayloadRow)) {
        return;
      }
      return keyword.payload[keyword.position - 1].url.replace(
        /^(https?:\/\/)?(www\.)?/,
        ""
      );
    },
    regexUrl(url) {
      if (!isNil(url)) {
        return url.replace(/^(https?:\/\/)?(www\.)?/, "");
      }
    },
    isBulditLink(value, index) {
      if (
        value.isBuldit === true &&
        value.position !== this.keyword.position &&
        index < 10
      ) {
        return true;
      }
    },
    isNoBulditLink(value, index) {
      if (
        value.isBuldit === false &&
        value.position !== this.keyword.position &&
        index < 10
      ) {
        return true;
      }
    },
    keywordHasRemark() {
      if (this.keyword.remark !== "" && this.keyword.remark !== null) {
        return true;
      }
    }
  }
};
</script>
<style scoped>
ul {
  padding-left: 0 !important;
  overflow-x: hidden;
  white-space: nowrap;
  width: 18vw;
  text-overflow: ellipsis;
}
li {
  display: inline;
}
</style>
