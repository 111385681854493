<template>
  <v-autocomplete
    v-model="selectedTags"
    :loading="isLoading"
    :disabled="isLoading"
    :items="selectableTags"
    multiple
    item-text="name"
    item-value="name"
    label="Tags"
    @input="$emit('update:tags', selectedTags)"
  />
</template>
<script>
import { clone } from "lodash";

export default {
  name: "tag-selector",
  props: {
    filters: {
      type: Object,
      required: false
    },
    tags: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      selectedTags: []
    };
  },
  computed: {
    isInitialized() {
      return this.$store.getters["entities/isInitialized"]({ name: "tag" });
    },
    isLoading() {
      return this.$store.getters["entities/isLoading"]({ name: "tag" });
    },
    selectableTags() {
      if (this.isLoading) return [];

      return this.$store.getters["entities/getTagsByType"]({
        type: this.filters.type
      });
    }
  },
  mounted() {
    this.getDataFromApi();
    this.selectedTags = clone(this.tags);
  },
  methods: {
    async getDataFromApi() {
      if (!this.isInitialized) {
        await this.$store.dispatch("entities/fetchTag", {
          filters: this.filters
        });
      }
    }
  }
};
</script>
