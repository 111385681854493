var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-subheader',[_vm._v("What do you want to update?")]),_c('v-flex',{staticClass:"text-xs-center",staticStyle:{"min-height":"60px !important"}},[_c('v-btn-toggle',{attrs:{"depressed":""},model:{value:(_vm.toggle_exclusive_update_type),callback:function ($$v) {_vm.toggle_exclusive_update_type=$$v},expression:"toggle_exclusive_update_type"}},[_c('v-btn',{attrs:{"outline":"","depressed":"","color":"primary"},on:{"click":_vm.setUpdateTypeContent}},[_vm._v(" Content Plan ")]),_c('v-btn',{attrs:{"outline":"","depressed":"","color":"primary"},on:{"click":_vm.setUpdateTypeSubject}},[_vm._v(" Subject Topic ")])],1)],1),(_vm.updateType == 'ContentPlan')?_c('v-container',{attrs:{"fluid":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('entity-data-provider',{attrs:{"entity-name":"contentPlan","includes":[
          'subjectTopics.keywordClusters.keywords',
          'localization',
          'subjectTopics.localization'
        ]},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-autocomplete',{attrs:{"loading":props.isLoading,"disabled":props.isLoading,"items":props.data,"clearable":"","item-text":"title","item-value":"id","label":"Content Plan"},model:{value:(_vm.contentPlanToUpdate),callback:function ($$v) {_vm.contentPlanToUpdate=$$v},expression:"contentPlanToUpdate"}})]}}],null,false,2937223640)})],1),(_vm.contentPlanToUpdate !== null)?[_c('v-subheader',{staticStyle:{"padding":"0px"}},[_vm._v(" Content Plan Title: ")]),_c('locale-textbox',{attrs:{"specific-locales":_vm.specificLocales,"label-locales":_vm.contentPlanTitle,"locales-text":_vm.contentPlanTitle},on:{"update:labelLocales":function($event){_vm.contentPlanTitle=$event},"update:label-locales":function($event){_vm.contentPlanTitle=$event}}})]:_vm._e()],2):_vm._e(),(_vm.updateType == 'SubjectTopic')?_c('v-container',{attrs:{"fluid":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('entity-data-provider',{attrs:{"entity-name":"contentPlan","includes":[
          'subjectTopics.keywordClusters.keywords',
          'localization',
          'subjectTopics.localization'
        ]},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-autocomplete',{attrs:{"loading":props.isLoading,"disabled":props.isLoading,"items":props.data,"clearable":"","item-text":"title","item-value":"subjectTopics","label":"Content Plan"},model:{value:(_vm.selectedContentPlan),callback:function ($$v) {_vm.selectedContentPlan=$$v},expression:"selectedContentPlan"}})]}}],null,false,1820760088)}),(_vm.selectedContentPlan !== null)?_c('v-autocomplete',{attrs:{"items":_vm.subjectTopicsToUpdate,"clearable":"","item-text":"title","item-value":"id","label":"Subject Topic"},model:{value:(_vm.subjectTopicToUpdate),callback:function ($$v) {_vm.subjectTopicToUpdate=$$v},expression:"subjectTopicToUpdate"}}):_vm._e()],1),(_vm.subjectTopicToUpdate !== null)?_c('v-subheader',{staticStyle:{"padding":"0px"}},[_vm._v(" Subject Topic Title: ")]):_vm._e(),(_vm.subjectTopicToUpdate !== null)?_c('locale-textbox',{attrs:{"specific-locales":_vm.specificLocales,"label-locales":_vm.subjectTopicTitle,"locales-text":_vm.subjectTopicTitle},on:{"update:labelLocales":function($event){_vm.subjectTopicTitle=$event},"update:label-locales":function($event){_vm.subjectTopicTitle=$event}}}):_vm._e()],1):_vm._e(),(_vm.titleSet)?_c('v-flex',{staticClass:"text-xs-center"},[(_vm.titleSet)?_c('v-btn',{attrs:{"outline":"","color":"primary"},on:{"click":function($event){return _vm.update(_vm.updateType)}}},[_vm._v(" update "+_vm._s(_vm.updateType)+" ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }