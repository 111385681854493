<template>
  <v-card color="white">
    <v-dialog
      v-if="openDialog"
      v-model="internalDialog"
      max-width="1400"
      color="white"
    >
      <v-card color="white">
        <v-toolbar color="light-blue" class="primary">
          <v-toolbar-title class="white--text">
            Update keyword Cluster
          </v-toolbar-title>
        </v-toolbar>
        <template>
          <v-progress-linear
            color="accent"
            style="margin-top: 0px"
            :indeterminate="isLoading"
          />
        </template>
        <v-container>
          <v-subheader style="padding: 0px;">
            Create Keywords:
          </v-subheader>
          <template v-for="(keywordCreate, index) in keywordsCreate">
            <v-layout row :key="index">
              <v-flex xs2 mr-4>
                <v-text-field v-model="keywordCreate.title" label="Title" />
              </v-flex>
              <v-flex xs2 mr-1>
                <v-text-field
                  v-model="keywordCreate.locales.nl_be.searches"
                  label="nl_be searches"
                />
              </v-flex>
              <v-flex xs2 mr-1>
                <v-text-field
                  v-model="keywordCreate.locales.nl_nl.searches"
                  label="nl_nl searches"
                />
              </v-flex>
              <v-flex xs2 mr-1>
                <v-text-field
                  v-model="keywordCreate.locales.fr_be.searches"
                  label="fr_be searches"
                />
              </v-flex>
              <v-flex xs2 mr-1>
                <v-text-field
                  v-model="keywordCreate.locales.fr_fr.searches"
                  label="fr_fr searches"
                />
              </v-flex>
              <v-flex xs2 mr-1>
                <v-text-field
                  v-model="keywordCreate.locales.en_gb.searches"
                  label="en_gb searches"
                />
              </v-flex>
              <v-flex xs2 mr-1>
                <v-text-field
                  v-model="keywordCreate.locales.de_de.searches"
                  label="de_de searches"
                />
              </v-flex>
            </v-layout>
          </template>
          <v-btn fab dark small color="pink" @click="pushExtraKeyword">
            <v-icon dark>
              add
            </v-icon>
          </v-btn>
          <v-flex class="text-xs-center" style="min-height: 60px !important;">
            <v-btn
              outline
              depressed
              color="primary"
              @click="pushFilledKeywords"
            >
              Add Keywords
            </v-btn>
          </v-flex>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="keywords"
          :pagination.sync="pagination"
          disable-initial-sort
          :rows-per-page-items="[100]"
        >
          <keyword-cluster-detail-row
            slot="items"
            slot-scope="props"
            :keyword="props.item"
          />
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
import KeywordClusterDetailRow from "./KeywordClusterDialogRow";

export default {
  name: "keyword-cluster-dialog",
  components: {
    KeywordClusterDetailRow
  },
  props: {
    openDialog: {
      type: Boolean,
      default: true
    },
    keywordCluster: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data: () => ({
    isLoading: false,
    pagination: {
      rowsPerPage: 1000
    },
    keywords: [],
    keywordsCreate: [
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      }
    ],
    initialKeywordsCreate: [
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      },
      {
        title: null,
        contentPlanId: null,
        keywordClusterId: null,
        locales: {
          nl_be: {
            localeId: 3,
            searches: null
          },
          nl_nl: {
            localeId: 2,
            searches: null
          },
          fr_be: {
            localeId: 5,
            searches: null
          },
          fr_fr: {
            localeId: 4,
            searches: null
          },
          en_gb: {
            localeId: 1,
            searches: null
          },
          de_de: {
            localeId: 6,
            searches: null
          }
        }
      }
    ],
    keywordCreate: {
      title: null,
      contentPlanId: null,
      keywordClusterId: null,
      locales: {
        nl_be: {
          localeId: 3,
          searches: null
        },
        nl_nl: {
          localeId: 2,
          searches: null
        },
        fr_be: {
          localeId: 5,
          searches: null
        },
        fr_fr: {
          localeId: 4,
          searches: null
        },
        en_gb: {
          localeId: 1,
          searches: null
        },
        de_de: {
          localeId: 6,
          searches: null
        }
      }
    },
    keywordsToAdd: []
  }),
  computed: {
    internalDialog: {
      get() {
        return this.openDialog;
      },
      set() {
        this.$emit("close");
      }
    },
    headers() {
      let headers = [
        {
          text: "Locale",
          align: "left",
          value: "locale_code",
          sortable: false
        },
        { text: "Keywords", align: "left", value: "title", sortable: false },
        { text: "Tags", align: "left", value: "tags", sortable: false },
        { text: "searches", value: "searches", sortable: false },
        { text: "position", sortable: false },
        { text: "url", sortable: false }
      ];
      return headers;
    }
  },
  watch: {
    openDialog() {
      this.collectKeywords();
    }
  },
  methods: {
    async collectKeywords() {
      this.isLoading = true;
      this.keywords = await this.$store.dispatch("entities/fetchKeywordRight", {
        includes: ["locale"],
        filters: {
          keywordCluster: this.keywordCluster.id,
          customSort: "locale_id",
          customSortSecond: "search_count"
        },
        joins: ["localesRight"],
        pagination: this.pagination
      });
      this.isLoading = false;
    },
    pushExtraKeyword() {
      this.keywordsCreate.push(this.keywordCreate);
    },
    pushFilledKeywords() {
      let keywordIsEmpty = true;
      this.keywordsCreate.forEach(e => {
        if (e[Object.keys(e)[0]] != null && e[Object.keys(e)[0]] !== "") {
          keywordIsEmpty = false;
        }
        if (!keywordIsEmpty) {
          e.keywordClusterId = this.keywordCluster.id;
          e.contentPlanId = this.$route.params.id;
          this.keywordsToAdd.push(e);
          keywordIsEmpty = true;
        }
      });
      this.saveKeywords();
    },
    async saveKeywords() {
      if (this.keywordsToAdd.length > 0) {
        await this.$store.dispatch("entities/storeKeywords", {
          keywords: this.keywordsToAdd
        });
        this.keywordsToAdd = [];
        this.reset();
        this.collectKeywords();
      }
    },
    reset() {
      Object.assign(
        this.$data.keywordsCreate,
        this.$data.initialKeywordsCreate
      );
    }
  }
};
</script>
